import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { navigate } from 'gatsby';

import { withSEO } from 'components/_hoc';
import Login from 'components/Login';

const LOGIN_URL = '/admin/user/login';

/**
 * Admin login page
 */
const AdminLoginPage: FC<{}> = () => {
  const [isVerifying, setIsVerifying] = useState<boolean>(true);

  useEffect((): void => {
    if (window.localStorage.getItem('admin.isAuthenticated') === 'true') {
      navigate(process.env.ADMIN_PLATFORM_URL);
    } else {
      setIsVerifying(false);
    }
  }, []);

  return isVerifying ? null : <Login page="administrator" url={LOGIN_URL} />;
};

export default withSEO(AdminLoginPage, {
  title: 'Login • Administator',
  description:
    'Admin login for Einstein Studios. Sign in to access the internal Einstein Studios administrator platform.',
  pathname: '/login/admin',
});
